import React, {useCallback, useMemo, useRef, useState} from "react";
import PropTypes from 'prop-types';
import {Form} from "react-bootstrap";
import Autosuggest from 'react-autosuggest';
import axios from "axios";
import debounce from "lodash/debounce";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/pro-regular-svg-icons/faSpinner";

import './styles.scss';

const FormAddressLookup = ({label, name, id, required, error, setValue, watch, register}) => {

    const manualAddressRef = useRef(null);
    const [autoFocusAutosuggest, setAutoFocusAutosuggest] = useState(false);
    const [addressQuery, setAddressQuery] = useState('');
    const [addressLookupError, setAddressLookupError] = useState(null);
    const [addressSuggestions, setAddressSuggestions] = useState([]);
    const [addressLookupSearching, setAddressLookupSearching] = useState(false);
    const [enterAddressManually, setEnterAddressManually] = useState(false);
    const [cachedResults, setCachedResults] = useState([]);
    const formValue = watch(name);
    const allowAddressLookup = useMemo(() => !enterAddressManually && (!formValue || formValue === ''), [enterAddressManually, formValue]);

    const onSuggestionSelected = (event, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) => {
        setValue(name, suggestionValue);
    };

    const fetchAndUpdateSuggestions = useCallback(debounce((value) => {
        axios.request({
            method: 'get',
            timeout: 3000,
            url: `${process.env.REACT_APP_ADDY_API_URL}?key=${process.env.REACT_APP_ADDY_API_KEY}&max=10&s=${value}`
        })
            .then(res => {
                const matches = res.data.addresses.map(address => address.a);
                setCachedResults([
                    ...cachedResults,
                    {
                        query: value,
                        addresses: matches
                    }
                ]);
                setAddressSuggestions(matches);
            })
            .catch(err => {
                setAddressLookupError(true);
            })
            .finally(() => {
                setAddressLookupSearching(false);
            });
    }, 1000), [cachedResults]);

    const onSuggestionsFetchRequested = ({value}) => {
        if (value !== '' && value.length > 3) {
            setAddressLookupSearching(true);

            const cachedMatches = cachedResults.find(c => c.query === value);

            if (cachedMatches) {
                setAddressSuggestions(cachedMatches.addresses);
                setAddressLookupSearching(false);
            } else {
                fetchAndUpdateSuggestions(value);
            }
        }
    };

    const onSuggestionsClearRequested = () => {
        setAddressSuggestions([]);
    };

    const changeAddress = useCallback(() => {
        setValue(name, '');
        setAutoFocusAutosuggest(true);
        setEnterAddressManually(false);
        setAddressQuery('');
    }, [name, setValue]);

    const handleEnterAddressManually = useCallback(() => {
        setEnterAddressManually(true);
        setTimeout(() => manualAddressRef.current.focus(), 200);
    }, []);

    return (
        <Form.Group controlId={id}>
            <Form.Label>
                {label} {required && <span className="form-required">*</span>}
            </Form.Label>

            <p>Type the first line of your address to start searching</p>

            <Form.Control
                name={name}
                type="text"
                placeholder={'Enter address manually'}
                ref={(e) => {
                    register(e);
                    manualAddressRef.current = e;
                }}
                readOnly={!enterAddressManually}
                isInvalid={!!error}
                className={allowAddressLookup ? 'd-none' : ''}/>

            {
                !enterAddressManually && !allowAddressLookup &&
                <div className="mt-2"><span onClick={changeAddress} className="link">Change address</span></div>
            }

            {
                enterAddressManually &&
                <div className="mt-2"><span onClick={changeAddress} className="link">Search for address</span></div>
            }

            {
                allowAddressLookup &&
                <div className="autosuggest-container">
                    <Autosuggest
                        suggestions={addressSuggestions}
                        onSuggestionSelected={onSuggestionSelected}
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        getSuggestionValue={(suggestion) => suggestion}
                        renderSuggestion={(suggestion) => <span>{suggestion}</span>}
                        inputProps={{
                            autoFocus: autoFocusAutosuggest,
                            placeholder: 'Search for your address',
                            value: addressQuery,
                            autoComplete: "nope",
                            onChange: (event, {newValue}) => setAddressQuery(newValue),
                            className: `form-control autosuggest-input ${!!error ? 'is-invalid' : ''}`
                        }}
                    />

                    <div className="autosuggest-searching">
                        {
                            addressLookupSearching &&
                            <FontAwesomeIcon icon={faSpinner} spin/>
                        }
                    </div>
                </div>
            }

            {
                !!error &&
                <Form.Control.Feedback type="invalid" className="d-block">
                    {error.message}
                </Form.Control.Feedback>
            }

            {
                allowAddressLookup &&
                    <div>
                        {
                            addressLookupError &&
                            <div className="invalid-feedback d-block">
                                The address lookup service appears to be unavailable. Try <span className="link text-danger" style={{'textDecoration': 'underline'}} onClick={handleEnterAddressManually}>entering the address manually</span>.
                            </div>
                        }
                        <div className="mt-2">
                            <span className="link" onClick={handleEnterAddressManually}>Can't find address? Enter it manually</span>
                        </div>
                    </div>
            }
        </Form.Group>
    );
};

FormAddressLookup.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    required: PropTypes.bool,
    error: PropTypes.object,
    setValue: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired
};

FormAddressLookup.defaultProps = {
    required: false
};

export default FormAddressLookup;
