import React, {useEffect} from "react";
import {Alert, Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import FormField from "../../../components/forms/FormField";
import {Link} from "react-router-dom";
import {useFormManager} from "../../../util/form-manager";
import FormButton from "../../../components/forms/FormButton";
import {runScrollToTop} from "../../../util/scroll-to-top";
import FormErrorsAlert from "../../../components/forms/FormErrorsAlert";
import FormAddressLookup from "../../../components/forms/FormAddressLookup";

const AccountSchema = yup.object().shape({
    legal_entity_name: yup.string().required("Full legal name of entity is required"),
    email_address: yup.string().email("Must be a valid email address").required("Email address is required"),

    physical_address: yup.string().required("Physical address is required"),
    postal_address: yup.string().required("Postal address is required"),
    phone: yup.string(),
    mobile: yup.string().required("Mobile phone is required"),

    authorised_person_first_name: yup.string().required("First name is required"),
    authorised_person_last_name: yup.string().required("Last name is required"),
    authorised_person_email_address: yup.string().email("Must be a valid email address").required("Email address is required"),

    independent_contact_name: yup.string().required("Contact person name is required"),
    independent_contact_address: yup.string().required("Contact person address is required"),
    independent_contact_phone: yup.string().required("Contact person phone is required")

});

const Account = () => {
    const {register, handleSubmit, errors, setValue, watch, setError} = useForm({validationSchema: AccountSchema});
    const {updateFormStep, loadSavedFormValues, submitFormStep, submitFormStepLoading} = useFormManager();

    const onSubmit = async data => {
        const manipulate = (data) => {
            const updated = {...data};
            updated.authorised_person_full_name = `${updated.authorised_person_first_name} ${updated.authorised_person_last_name}`;
            return updated;
        };
        submitFormStep({data, step: 1, url: "/business-aviation/guarantors", setError, manipulate});
    };

    useEffect(() => {
        runScrollToTop();
        updateFormStep(1);
        loadSavedFormValues(setValue);
    }, [setValue, updateFormStep, loadSavedFormValues]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormErrorsAlert errors={errors}/>

            <div className="section">
                <h2>Business Details</h2>

                <div className="row">
                    <div className="col-md-6">
                        <FormField
                            label="Full Legal Name of Entity"
                            name="legal_entity_name"
                            ref={register}
                            id="legal_entity_name"
                            type="text"
                            required={true}
                            error={errors.legal_entity_name} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Email Address" name="email_address" ref={register} id="email_address" type="email" required={true}
                                   error={errors.email_address}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <FormAddressLookup
                            label="Physical Address"
                            name="physical_address"
                            register={register}
                            id="physical_address"
                            required={true}
                            setValue={setValue}
                            watch={watch}
                            error={errors.physical_address} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <FormAddressLookup
                            label="Postal Address"
                            name="postal_address"
                            register={register}
                            id="postal_address"
                            required={true}
                            setValue={setValue}
                            watch={watch}
                            error={errors.postal_address} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Mobile Number" name="mobile" ref={register} id="mobile" type="text" required={true} error={errors.mobile}/>
                    </div>
                    <div className="col-md-6">
                        <FormField label="Phone Number" name="phone" ref={register} id="phone" type="text" required={false} error={errors.phone}/>
                    </div>
                </div>
            </div>


            <div className="section">
                <h2>Authorised Representative</h2>

                <Alert variant="warning">
                    <p>Please note, this person must be authorised to sign on behalf of the company / trust.</p>
                    <p>
                        On completion of this form, applicants will be sent an email inviting them to
                        sign the form electronically. When signing the form, all applicants will need to upload
                        a copy of their passport or driving license.
                    </p>
                </Alert>
                <div className="row">
                    <div className="col-md-6">
                        <FormField label="First Name" name="authorised_person_first_name" ref={register} id="authorised_person_first_name" type="text" required={true}
                                   error={errors.authorised_person_first_name}/>
                    </div>
                    <div className="col-md-6">
                        <FormField label="Last Name" name="authorised_person_last_name" ref={register} id="authorised_person_last_name" type="text" required={true}
                                   error={errors.authorised_person_last_name}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Email Address" name="authorised_person_email_address" ref={register} id="authorised_person_email_address" type="email" required={true}
                                   error={errors.authorised_person_email_address}/>
                    </div>
                </div>
            </div>



            <div className="section">
                <h2>Accountant Details</h2>

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Full Name" name="accountant_name" ref={register} id="accountant_name" type="text" error={errors.accountant_name}/>
                    </div>
                    <div className="col-md-6">
                        <FormField label="Business Name" name="accountant_business_name" ref={register} id="accountant_business_name" type="text"
                                   error={errors.accountant_business_name}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <FormAddressLookup
                            label="Business Postal Address"
                            name="accountant_postal_address"
                            register={register}
                            id="accountant_postal_address"
                            setValue={setValue}
                            watch={watch}
                            error={errors.accountant_postal_address} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Phone Number" name="accountant_phone" ref={register} id="accountant_phone" type="text"
                                   error={errors.accountant_phone}/>
                    </div>
                </div>
            </div>

            <div className="section">
                <h2>Independent Contact Person</h2>

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Full Name" name="independent_contact_name" ref={register} id="independent_contact_name" type="text" required={true}
                                   error={errors.independent_contact_name}/>
                    </div>
                    <div className="col-md-6">
                        <FormField label="Phone" name="independent_contact_phone" ref={register} id="independent_contact_phone" type="text" required={true}
                                   error={errors.independent_contact_phone}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <FormAddressLookup
                            label="Postal Address"
                            name="independent_contact_address"
                            register={register}
                            id="independent_contact_address"
                            required={true}
                            setValue={setValue}
                            watch={watch}
                            error={errors.independent_contact_address} />
                    </div>
                </div>

            </div>


            <div className="section">
                <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start">
                        <Button disabled={submitFormStepLoading} as={Link} to="/business-aviation" variant="light">Back</Button>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-end">
                        <FormButton loading={submitFormStepLoading} variant="primary" type="submit">Next</FormButton>
                    </div>
                </div>
            </div>

        </Form>
    );
};

export default Account;
