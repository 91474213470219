import React from "react";
import PropTypes from 'prop-types';
import {Form} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {parse, format} from 'date-fns';

const FormDateField = React.forwardRef(({label, name, id, required, error, watch, setValue}, ref) => {

    const date = watch(name);

    const selectedDate = date ? parse(date, "yyyy-MM-dd", new Date()) : null;

    return (
        <Form.Group controlId={id}>
            <Form.Label>{label} {required && <span className="form-required">*</span>}</Form.Label>
            <input type="hidden" name={name} ref={ref} />

            <DatePicker
                selected={selectedDate}
                onChange={date => setValue(name, format(date, "yyyy-MM-dd"))}
                dateFormat="dd MMM yyyy"
                maxDate={new Date()}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                className={`form-control ${!!error ? 'is-invalid' : ''}`}
            />

            {
                !!error &&
                <Form.Control.Feedback type="invalid" className="d-block">
                    {error.message}
                </Form.Control.Feedback>
            }
        </Form.Group>
    );
});

FormDateField.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    required: PropTypes.bool,
    error: PropTypes.object
};

FormDateField.defaultProps = {
    required: false
};

export default FormDateField;
