import React, {useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {Link} from "react-router-dom";
import {useFormManager} from "../../../util/form-manager";
import FormField from "../../../components/forms/FormField";
import {runScrollToTop} from "../../../util/scroll-to-top";
import FormButton from "../../../components/forms/FormButton";
import FormErrorsAlert from "../../../components/forms/FormErrorsAlert";
import FormAddressLookup from "../../../components/forms/FormAddressLookup";
import get from "lodash/get";

const DeliveryDetailsSchema = yup.object().shape({
    deliver_to_physical_address: yup.bool(),
    delivery_address: yup.string().when(
        "deliver_to_physical_address",
        (value, schema) => value === false ? schema.required("You must enter a delivery address") : schema
    ),
    delivery_instructions: yup.string(),
    fuel_tank_size: yup.string().required("Fuel tank size is required")
});

const DeliveryDetails = () => {
    const {register, handleSubmit, errors, setValue, watch, setError} = useForm({validationSchema: DeliveryDetailsSchema});
    const {updateFormStep, loadSavedFormValues, submitFormStep, submitFormStepLoading} = useFormManager();

    const [physicalAddress, setPhysicalAddress] = useState(null);
    const [deliverToPhysicalAddress, setDeliverToPhysicalAddress] = useState(false);

    const onSubmit = async data => {
        const submitData = !deliverToPhysicalAddress ? data : { ...data, delivery_address: physicalAddress };
        submitFormStep({data: submitData, step: 2, url: "/home-heating/payment", setError});
    };

    useEffect(() => {
        runScrollToTop();
        updateFormStep(2, "/home-heating");
        const savedValues = loadSavedFormValues(setValue);
        setDeliverToPhysicalAddress(get(savedValues, "deliver_to_physical_address", false));
        setPhysicalAddress(savedValues['physical_address'] || null);
    }, [loadSavedFormValues, setValue, updateFormStep]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormErrorsAlert errors={errors} />
            <div className="section">
                <h2>Delivery Details</h2>

                <div className="row">
                    <div className="col-md-12">
                        <Form.Group controlId="deliver_to_physical_address">
                            <Form.Check
                                custom
                                type="checkbox"
                                name="deliver_to_physical_address"
                                ref={register}
                                label={<>Deliver to my physical address - <strong>{physicalAddress}</strong></>}
                                onClick={(e) => {
                                    const checked = e.target.checked;
                                    setDeliverToPhysicalAddress(checked);
                                }} />
                        </Form.Group>
                    </div>
                </div>

                {
                    !deliverToPhysicalAddress &&
                        <div className="row">
                            <div className="col-md-12">
                                <FormAddressLookup
                                    label="Delivery Address"
                                    name="delivery_address"
                                    register={register}
                                    id="delivery_address"
                                    required={true}
                                    setValue={setValue}
                                    watch={watch}
                                    error={errors.delivery_address} />
                            </div>
                        </div>
                }

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Delivery Instructions"
                                   name="delivery_instructions"
                                   ref={register}
                                   id="delivery_instructions"
                                   type="text"
                                   error={errors.delivery_instructions} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Fuel Tank Size"
                                   name="fuel_tank_size"
                                   ref={register}
                                   id="fuel_tank_size"
                                   type="text"
                                   required={true}
                                   error={errors.fuel_tank_size} />
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start">
                        <Button disabled={submitFormStepLoading} as={Link} to="/home-heating/account" variant="light">Back</Button>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-end">
                        <FormButton loading={submitFormStepLoading} variant="primary" type="submit">Next</FormButton>
                    </div>
                </div>
            </div>

        </Form>
    );
};

export default DeliveryDetails;
